import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export function convertDate(v: unknown): Date | null {
  if (typeof v === "object") {
    if (dayjs.isDayjs(v)) return v.toDate();
    if (v instanceof Date) return v;
  } else if (typeof v === "number" || typeof v === "string") return new Date(v);
  return null;
}

export function convertDayjs(v: Date): dayjs.Dayjs {
  return dayjs(v);
}

export function dateFormatLocal(
  v: string | number | Date,
  template = "YYYY/MM/DD HH:mm:ss"
): string {
  return dayjs(v).format(template);
}
type RelativeTimeLang = {
  IN?: string;
  AGO?: string;
  YEAR?: string;
  MONTH?: string;
  DAY?: string;
  HOUR?: string;
  MINUTE?: string;
  REPLACE?: {
    [str: string]: string;
  };
  SPECIAL?: {
    [str: string]: string;
  };
};
export const relativeTimeLang_zhtw: RelativeTimeLang = {
  IN: "前$",
  AGO: "後",
  YEAR: "年",
  MONTH: "個月",
  DAY: "天",
  HOUR: "個小時",
  MINUTE: "分",
  REPLACE: {
    "A FEW SECONDS": "幾秒",
  },
};
function matchList<T>(originList: T[], searchList: T[]): boolean {
  if (originList.length !== searchList.length) return false;
  for (let index = 0; index < searchList.length; index++) {
    if (originList[index] !== searchList[index] && searchList[index] !== null)
      return false;
  }
  return true;
}
function indexOfWords(originWords: string[], searchWords: string[]): number {
  for (
    let index = 0;
    index + searchWords.length <= originWords.length;
    ++index
  ) {
    if (
      matchList(
        originWords.slice(index, index + searchWords.length),
        searchWords
      )
    )
      return index;
  }
  return -1;
}
function replaceString(
  originWords: string[],
  search: string | string[],
  replaceStr: string
) {
  const searchList = Array.isArray(search) ? search : [search];
  for (const searchStr of searchList) {
    const words = searchStr.split(" ");
    const index = indexOfWords(originWords, words);
    if (index === -1) continue;
    originWords.splice(index, words.length);
    if (replaceStr.startsWith("^")) {
      replaceStr = replaceStr.substring(1);
      originWords.unshift(...replaceStr.split(" "));
    } else if (replaceStr.endsWith("$")) {
      replaceStr = replaceStr.substring(0, replaceStr.length - 1);
      originWords.push(...replaceStr.split(" "));
    } else {
      originWords.splice(index, 0, ...replaceStr.split(" "));
    }
    return;
  }
}
export function dateRelativeTime(
  v: string | number | Date,
  lang: RelativeTimeLang = relativeTimeLang_zhtw
): string {
  const words = dayjs(v).toNow().toUpperCase().split(" ");
  const { IN, AGO, REPLACE, SPECIAL, ...UNITS } = lang;
  if (typeof SPECIAL === "object") {
    for (const SPECIAL_KEY in SPECIAL) {
      replaceString(words, SPECIAL_KEY, SPECIAL[SPECIAL_KEY]);
    }
  }
  if (typeof IN === "string") replaceString(words, "IN", IN);
  if (typeof AGO === "string") replaceString(words, "AGO", AGO);
  if (typeof REPLACE === "object") {
    for (const REPLACE_KEY in REPLACE) {
      replaceString(words, REPLACE_KEY, REPLACE[REPLACE_KEY]);
    }
  }
  for (const UNIT_KEY of ["YEAR", "MONTH", "DAY", "HOUR", "MINUTE"] as const) {
    const UNIT_VALUE = UNITS[UNIT_KEY];
    if (typeof UNIT_VALUE === "string")
      replaceString(words, [UNIT_KEY, `${UNIT_KEY}S`], UNIT_VALUE);
  }
  replaceString(words, ["A", "AN"], "1");

  return words
    .join(" ")
    .replaceAll(/([^\w])\s([^\w])/g, "$1$2")
    .trim();
}
