import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout,signInWithGoogle } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { dateFormatLocal } from "../../utils/date";
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    CircularProgress,
    FormControl,
    OutlinedInput,
    Paper,
    Typography,
    useTheme,
    autocompleteClasses,
  } from "@mui/material";
import { width } from "@mui/system";
import { isTemplateMiddle } from "typescript";
import { log } from "console";
import { userInfo } from "os";
import { access } from "fs";

export default function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
    if(user){
      // console.log("使用者資料:",user.accessToken);
      user.getIdToken().then(function(idToken) {  // <------ Check this line
          alert(idToken); // It shows the Firebase token now
          return idToken;
      });
      console.log(user.displayName);
    } 
  }, [user, loading]);
  const livelist = [
    {
      id: 1,
      名稱: "影片一",
      瀏覽權限: "不公開",
      日期: new Date("2022-01-01 10:00"),
      觀看次數: 1,
      留言數: 1,
    },
    {
      id: 2,
      瀏覽權限: "公開",
      日期: new Date("2022-01-01 10:00"),
      觀看次數: 1,
      留言數: 1,
    },
  ];
  return (
    <>
    <Grid  display={"flex"}>
        <Grid  
          item
          xs={12} sm={6} lg={3}
          sx={{ 
          p: 2.625,
          borderRight:2,
          flexDirection: "column",
          width: "20%",
          minHeight: "100%",
          maxWidth: "100%",
          alignItems: "center",
        }}>
            <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
            >  
              <Typography>
                <Typography variant={"h5"}  component={"span"}>
                  歡迎回來!&nbsp;&nbsp;
                </Typography>
                {name}
              </Typography>
          </Box>
          <Grid item sx={{
                width:"100%",
                my:2,
              }}>
            <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
              sx={{
                py:1,
              }}
            >  
              <Typography>
               播放清單
              </Typography>
            </Box>
            <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
              sx={{
                py:1,
              }}
            >  
              <Typography>
               數據分析
              </Typography>
            </Box>
            <Box
                width={"100%"} 
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  py:1,
                }}
              >  
                <Typography>
                  留言
                </Typography>
            </Box>
            <Box
                width={"100%"} 
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  py:1,
                }}
              >  
                <Typography>
                  字幕
                </Typography>
            </Box>
          </Grid>
          <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
            >  
              <Typography>
              使用者設定
              </Typography>
          </Box>
          <Box 
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}>
              <Button
                  variant={"text"}
                  sx={{
                  py: 1.375,
                  px: 2,
                  }}
                  onClick={logout}
                  >
                  <Typography fontSize={16} lineHeight={1.125}>
                      登出
                  </Typography>
              </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={8} sx={{ p: 5.25,width: "80%"}}>
          <Box width={"100%"} 
              display={"flex"}
              justifyContent={"space-between"}
              sx={{
                mb: 5,
              }}
          >
            <Typography variant={"h4"} align={"left"} sx={{ flexGrow: 1 }}>
              頻道內容
            </Typography>
            <Button
                  variant={"text"}
                  sx={{
                  py: 1.375,
                  px: 2,
                  border: 1,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href='/CreateLiveStream';
                    }}
                  >
                  <Typography fontSize={16} lineHeight={1.125}>
                      建立直播
                  </Typography>
              </Button>
          </Box>
          <table  width={"100%"}>
            <Box sx={{ mb: 6,minWidth:"100%" }} >
                <tr>
                  <th>
                    <Typography variant={"h5"} sx={{ mb: 1.5 }}>影片</Typography>
                  </th>
                  <th>
                    <Typography variant={"h5"} sx={{ mb: 1.5 }}>瀏覽權限</Typography>
                  </th>
                  <th>
                    <Typography variant={"h5"} sx={{ mb: 1.5 }}>日期</Typography>
                  </th>
                  <th>
                    <Typography variant={"h5"} sx={{ mb: 1.5 }}>觀看次數</Typography>
                  </th>
                  <th>
                    <Typography variant={"h5"} sx={{ mb: 1.5 }}>留言</Typography>
                  </th>
                </tr> 
              {livelist.map((live, livelist) => {
                return (
                  <tr key={livelist}>
                    <td>
                      <CardMedia
                        component={"img"}
                        alt={"頭貼"}
                        height={"128"}
                        image={"/images/default-member.jpg"}
                      />
                    </td>
                    <td style={{verticalAlign:"middle",textAlign:"center",width: "20%"}}>
                      <Typography variant={"h5"} color={"text.secondary"}  >
                        {live.瀏覽權限}
                      </Typography>
                    </td>
                    <td style={{verticalAlign:"middle",textAlign:"center",width: "20%"}}>
                      <Typography variant={"h5"} color={"text.secondary"}  >
                        {dateFormatLocal(live.日期, "YYYY-MM-DD")}
                      </Typography>
                    </td>
                    <td style={{verticalAlign:"middle",textAlign:"center",width: "20%"}}>
                      <Typography variant={"h5"} color={"text.secondary"}  >
                        {live.觀看次數}
                      </Typography>
                    </td>
                    <td style={{verticalAlign:"middle",textAlign:"center",width: "20%"}}>
                      <Typography variant={"h5"} color={"text.secondary"}  >
                        {live.留言數}
                      </Typography>
                    </td>
                   
                  </tr>
                )
              })}
              {/* {livelist.map((live,livelist) => (
                <Grid key={livelist.id} item xs={12} sm={6} lg={3} sx={{py:2}} display={"flex"}>
                    <Card sx={{ maxWidth: 345 }}>
                      <CardMedia
                        component={"img"}
                        alt={"頭貼"}
                        height={"256"}
                        image={"/images/default-member.jpg"}
                      />
                    </Card>
                    <Typography variant={"caption"} color={"text.secondary"}>
                        {dateFormatLocal(livelist.日期, "YYYY-MM-DD")}
                    </Typography>
                </Grid>
              ))} */}
            </Box>
          </table>
        </Grid>
    </Grid>
    
    </>
    
  );
}
