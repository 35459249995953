import { AppBar, AppBarTypeMap, Toolbar } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";

export type NavBarProps = DefaultComponentProps<AppBarTypeMap> & {
  /** 透明背景 + 不置頂 */
  transparent?: boolean;
  /** 高度 */
  height?: number;
};

export default function NavBar({ transparent, height, ...props }: NavBarProps) {
  return (
    <>
      <AppBar
        {...props}
        sx={{
          ...props.sx,
          backgroundColor: transparent
            ? "initial"
            : (
                props.sx as import("@mui/system").SystemCssProperties<
                  import("@mui/material").Theme | undefined
                >
              )?.backgroundColor,
        }}
        elevation={transparent ? 0 : props.elevation}
        position={
          props.position ? props.position : transparent ? "static" : "sticky"
        }
      >
        <Toolbar
          sx={{
            alignItems: "stretch",
            minHeight: height,
          }}
        >
          {props.children}
        </Toolbar>
      </AppBar>
    </>
  );
}
