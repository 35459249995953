import {
  Button,
  Container,
  FormControl,
  Grid,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  registerWithEmailAndPassword,
} from "../firebase";

export default function RegisterPage() {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user){ 
      alert("註冊成功");
      navigate("/user");
    };
  }, [user, loading]);
  return (
    <Container maxWidth={"sm"} sx={{ py: 5.25 }}>
      <Typography variant={"h4"} textAlign={"center"} sx={{ mb: 5.5 }}>
        註冊
      </Typography>
      <form>
        <Grid container spacing={2} sx={{ mb: 8 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontSize={14} lineHeight={1.143} sx={{ mb: 0.5 }}>
                <Typography component={"span"} color={theme.palette.error.main}>
                  *
                </Typography>
                姓名
              </Typography>
              <OutlinedInput
                placeholder={"請輸入姓名"}
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontSize={14} lineHeight={1.143} sx={{ mb: 0.5 }}>
                <Typography component={"span"} color={theme.palette.error.main}>
                    *
                </Typography>
                信箱
              </Typography>
              <OutlinedInput 
                placeholder={"請輸入信箱"} 
                fullWidth 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography fontSize={14} lineHeight={1.143} sx={{ mb: 0.5 }}>
                <Typography component={"span"} color={theme.palette.error.main}>
                  *
                </Typography>
                密碼
              </Typography>
              <OutlinedInput
                type={"password"}
                placeholder={"請輸入密碼"}
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </Grid>

        </Grid>
        <Button variant={"contained"} fullWidth onClick={register} sx={{ mb: 0.5 }}>
          註冊
        </Button>
        {/* <Grid item xs={12} textAlign={"center"} sx={{ m: 1 }}>
          <Typography component={"span"} >
              OR
          </Typography>
        </Grid>
        <Button type={"submit"} variant={"contained"} fullWidth onClick={signInWithGoogle} sx={{ mb: 0.5 }}>
          Register with Google
        </Button> */}
      </form>
    </Container>
  );
}
