import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { dateFormatLocal } from "../../utils/date";
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    CircularProgress,
    FormControl,
    OutlinedInput,
    Paper,
    Typography,
    Switch,
    useTheme,
    autocompleteClasses,
  } from "@mui/material";
import { width } from "@mui/system";
import { isTemplateMiddle } from "typescript";

export default function LiveComment() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [類型, 設置類型] = React.useState<"已回覆" | "待回覆">("已回覆");
  const theme = useTheme();
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/");
    fetchUserName();
  }, [user, loading]);
  const livelist = [
    {
      id: 1,
      留言姓名: "路人甲",
      留言內容: "好驚險啊!",
      留言日期: new Date("2022-01-01 10:00"),
    },
    {
      id: 2,
      留言姓名: "路人乙",
      留言內容: "大大的攻略很詳細喔",
      留言日期: new Date("2022-01-01 10:00"),
    },
  ];
  return (
    <>
    <Grid  display={"flex"}>
        <Grid  
          item
          xs={12} sm={6} lg={3}
          sx={{ 
          p: 2.625,
          borderRight:2,
          flexDirection: "column",
          width: "20%",
          minHeight: "100%",
          maxWidth: "100%",
          alignItems: "center",
        }}>
            <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
            >  
              <Typography>
                <Typography variant={"h5"}  component={"span"}>
                  歡迎回來!&nbsp;&nbsp;
                </Typography>
                {name}
              </Typography>
          </Box>
          <Grid item sx={{
                width:"100%",
                my:2,
              }}>
            <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
              sx={{
                py:1,
              }}
            >  
              <Typography>
               播放清單
              </Typography>
            </Box>
            <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
              sx={{
                py:1,
              }}
            >  
              <Typography>
               數據分析
              </Typography>
            </Box>
            <Box
                width={"100%"} 
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  py:1,
                }}
              >  
                <Typography>
                  留言
                </Typography>
            </Box>
            <Box
                width={"100%"} 
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  py:1,
                }}
              >  
                <Typography>
                  字幕
                </Typography>
            </Box>
          </Grid>
          <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
            >  
              <Typography>
              使用者設定
              </Typography>
          </Box>
          <Box 
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}>
              <Button
                  variant={"text"}
                  sx={{
                  py: 1.375,
                  px: 2,
                  }}
                  onClick={logout}
                  >
                  <Typography fontSize={16} lineHeight={1.125}>
                      登出
                  </Typography>
              </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={8} sx={{ p: 5.25,width: "80%"}}>
          <Typography variant={"h4"} align={"left"} sx={{ flexGrow: 1 }}>
           影片留言
          </Typography>
          <Grid container sx={{ mb: 6.625 }} spacing={1.5}>
            <Grid item xs={6}>
              <Button
                variant={類型 === "已回覆" ? "contained" : "outlined"}
                disableRipple={類型 === "已回覆"}
                fullWidth
                onClick={() => 設置類型("已回覆")}
              >
                已回覆
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={類型 === "待回覆" ? "contained" : "outlined"}
                disableRipple={類型 === "待回覆"}
                fullWidth
                onClick={() => 設置類型("待回覆")}
              >
                待回覆
              </Button>
            </Grid>
          </Grid>
            <Box sx={{ mb: 6,minWidth:"100%" }} >
              
              {livelist.map((livelist) => (
                <Grid key={livelist.id} item xs={12} sm={6} lg={3} sx={{borderBottom:"1px solid #000",py:2}} >
                    <Grid display={"flex"}>
                        <Grid item alignItems={"center"}>
                            <Box    
                                width={80}
                                height={80}
                                sx={{
                                    borderRadius:15,
                                    backgroundImage: `url('${
                                        "/images/default-member.jpg"
                                    }')`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                }}

                                />
                        </Grid>
                        <Grid sx={{px:2}}>
                            <Grid >
                                <Typography variant={"caption"} color={"text.secondary"}>
                                    {livelist.留言姓名}&nbsp;。&nbsp;
                                </Typography>
                                <Typography variant={"caption"} color={"text.secondary"}>
                                    {dateFormatLocal(livelist.留言日期, "YYYY-MM-DD")}
                                </Typography>
                            </Grid>
                            <Grid sx={{py:1}}>
                                <Typography variant={"caption"} fontSize={16}>
                                    {livelist.留言內容}&nbsp;。&nbsp;
                                </Typography>
                            </Grid>
                            <Grid >
                                <Button>
                                    <Typography variant={"caption"} fontSize={12}>
                                        回覆
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
              ))}
            </Box>
        
        </Grid>
    </Grid>
    
    </>
    
  );
}
