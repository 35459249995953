import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { dateFormatLocal } from "../utils/date";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  CircularProgress,
  FormControl,
  OutlinedInput,
  Paper,
  Typography,
  useTheme,
  Accordion,
  InputBase,
  Radio,
  Switch,
  Dialog,
  DialogContent,
  IconButton,
  AccordionSummary,
  autocompleteClasses,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { width } from "@mui/system";
import { isTemplateMiddle } from "typescript";
import { Controller, useForm } from "react-hook-form";
import { 值班資料格式 } from "./type";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from '@mui/material/MenuItem';

export default function CreateLiveStream() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [新值班資料, 設置新值班資料] = React.useState<值班資料格式 | null>(
    null
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/");
    fetchUserName();
  }, [user, loading]);

  const {
    watch: watchChat,
    reset: resetChat,
    register: registerChat,
    handleSubmit: handleChatSubmit,
  } = useForm<{
    chatText: string;
  }>({ defaultValues: { chatText: "" } });

  const [selectedValue, setSelectedValue] = React.useState("a");

  const radiohandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const [checked, setChecked] = React.useState({
    connect: true,
    PG: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  const [cate, setcate] = React.useState('');
  const catehandleChange = (event: SelectChangeEvent) => {
    setcate(event.target.value as string);
  };
  return (
    <>
      <Grid display={"flex"}>
        <Grid
          item
          xs={12}
          sm={6}
          lg={3}
          sx={{
            p: 2.625,
            borderRight: 2,
            flexDirection: "column",
            width: "20%",
            minHeight: "100%",
            maxWidth: "100%",
            alignItems: "center",
          }}
        >
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Typography>
              <Typography variant={"h5"} component={"span"}>
                歡迎回來!&nbsp;&nbsp;
              </Typography>
              {name}
            </Typography>
          </Box>
          <Grid
            item
            sx={{
              width: "100%",
              my: 2,
            }}
          >
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              sx={{
                py: 1,
              }}
            >
              <a
                href={
                  "/user"
                }
              >
                <Typography>播放清單</Typography>
              </a>
              
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              sx={{
                py: 1,
              }}
            >
              <Typography>數據分析</Typography>
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              sx={{
                py: 1,
              }}
            >
              <Typography>留言</Typography>
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              sx={{
                py: 1,
              }}
            >
              <Typography>字幕</Typography>
            </Box>
          </Grid>
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Typography>使用者設定</Typography>
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <Button
              variant={"text"}
              sx={{
                py: 1.375,
                px: 2,
              }}
              onClick={logout}
            >
              <Typography fontSize={16} lineHeight={1.125}>
                登出
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid sx={{ p: 5.25, width: "80%" }}>
          <Grid item xs={12} sm={6} lg={8} display={"flex"}>
            <Box sx={{ width: "100%", maxWidth: "425px" }}>
              <Box
                sx={{
                  backgroundColor: "#000",
                  maxWidth: "425px",
                  minHeight: "240px",
                }}
              ></Box>
            </Box>

            <Box sx={{ mb: 2, px: 2, minWidth: "50%" }}>
              <Box>
                <Typography fontSize={16} lineHeight={1.125} sx={{ mb: 2 }}>
                  標題
                </Typography>
                <Typography variant={"h5"} lineHeight={1.125} sx={{ mb: 2 }}>
                  XX的直播
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography fontSize={16} lineHeight={1.125} sx={{ mb: 2 }}>
                  類別
                </Typography>
                <Typography variant={"h5"} lineHeight={1.125} sx={{ mb: 2 }}>
                  美食
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography fontSize={16} lineHeight={1.125} sx={{ mb: 2 }}>
                  隱私設定
                </Typography>
                <Typography variant={"h5"} lineHeight={1.125} sx={{ mb: 2 }}>
                  公開
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mb: 2, px: 2 }}>
              <Button
                variant={"text"}
                sx={{
                  py: 1.375,
                  px: 2,
                  border: "1px solid black",
                }}
                onClick={() =>
                  設置新值班資料({
                    id: new Date().getTime(),
                    諮詢方式: {
                      通話: false,
                      視訊: false,
                    },
                    開始時間: new Date(),
                    結束時間: new Date(),
                    備取人數: 0,
                    報名: [],
                  })
                }
              >
                <Typography fontSize={16} lineHeight={1.125}>
                  編輯
                </Typography>
              </Button>
            </Box>
          </Grid>
          <Grid>
            <Box>
              <Typography lineHeight={1.125} variant={"h5"} sx={{ my: 2.5 }}>
                串流金鑰
              </Typography>
              <Grid
                item
                xs={12}
                sm={6}
                lg={8}
                display={"flex"}
                sx={{ width: "100%", flexGrow: 1 }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  paddingBottom={"10px"}
                  width={"90%"}
                >
                  <Typography
                    fontSize={14}
                    lineHeight={1.143}
                    sx={{ pb: 0.5, maxWidth: "150px", width: "100%" }}
                  >
                    主要實況串流金鑰:&nbsp;&nbsp;
                  </Typography>
                  <OutlinedInput
                    sx={{ mx: 1, pb: 0.5, width: "80%" }}
                    value={"live_85838221_JFdO1GMWzaV6sq7nvzBxUlzIZW6q0E"}
                    readOnly={true}
                    type="password"
                  />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  paddingBottom={"10px"}
                  width={"15%"}
                >
                  <Button
                    variant={"text"}
                    sx={{
                      py: 1.375,
                      px: 2,
                      mr: 1,
                      border: "1px solid #000",
                    }}
                  >
                    <Typography fontSize={16} lineHeight={1.125}>
                      複製
                    </Typography>
                  </Button>
                  <Button
                    variant={"text"}
                    sx={{
                      py: 1.375,
                      px: 2,
                      ml: 1,
                      border: "1px solid #000",
                    }}
                  >
                    <Typography fontSize={16} lineHeight={1.125}>
                      重設
                    </Typography>
                  </Button>
                </Box>
              </Grid>
              <Box
                display={"flex"}
                alignItems={"center"}
                paddingBottom={"10px"}
                width={"100%"}
              >
                <Typography
                  fontSize={14}
                  lineHeight={1.143}
                  sx={{ pb: 0.5, maxWidth: "150px", width: "100%" }}
                >
                  串流網址
                </Typography>
                <OutlinedInput
                  sx={{ mx: 1, pb: 0.5, width: "90%" }}
                  value={"rtmp://a.rtmp.youtube.com/5"}
                  readOnly={true}
                />
                <Button
                  variant={"text"}
                  sx={{
                    py: 1.375,
                    px: 2,
                    mr: 1,
                    border: "1px solid #000",
                    width: "10%",
                  }}
                >
                  <Typography fontSize={16} lineHeight={1.125}>
                    複製
                  </Typography>
                </Button>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                paddingBottom={"10px"}
                width={"100%"}
              >
                <Typography
                  fontSize={14}
                  lineHeight={1.143}
                  sx={{ pb: 0.5, maxWidth: "150px", width: "100%" }}
                >
                  備用伺服器網址
                </Typography>
                <OutlinedInput
                  sx={{ mx: 1, pb: 0.5, width: "90%" }}
                  value={"rtmp://a.rtmp.youtube.com/?backup=15"}
                  readOnly={true}
                />
                <Button
                  variant={"text"}
                  sx={{
                    py: 1.375,
                    px: 2,
                    mr: 1,
                    border: "1px solid #000",
                    width: "10%",
                  }}
                >
                  <Typography fontSize={16} lineHeight={1.125}>
                    複製
                  </Typography>
                </Button>
              </Box>
              <Box display={"flex"} alignItems={"center"} width={"100%"}>
                <Typography
                  fontSize={16}
                  lineHeight={1.125}
                  sx={{ pb: 0.5, maxWidth: "150px", width: "100%" }}
                >
                  延遲模式:
                </Typography>
                <Box>
                  <Box display={"flex"}>
                    <Radio
                      checked={selectedValue === "a"}
                      onChange={radiohandleChange}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <Typography
                      fontSize={10}
                      lineHeight={1.125}
                      sx={{ py: 1.5 }}
                    >
                      低延遲：與觀眾近乎零時差互動的最佳選擇
                    </Typography>
                  </Box>
                  <Box display={"flex"}>
                    <Radio
                      checked={selectedValue === "b"}
                      onChange={radiohandleChange}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                    <Typography
                      fontSize={10}
                      lineHeight={1.125}
                      sx={{ py: 1.5 }}
                    >
                      一般延遲：如果您不需要與觀眾即時互動，請選擇這個設定。
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box display={"flex"} alignItems={"center"} width={"100%"}>
                <Typography
                  fontSize={16}
                  lineHeight={1.125}
                  sx={{ pb: 0.5, maxWidth: "150px", width: "100%" }}
                >
                  備份影片:
                </Typography>
                <Box>
                  <Switch
                    checked={checked.connect}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    name="connect"
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          width={550}
          display={"flex"}
          flexDirection={"column"}
          sx={{ px: 2.875 }}
        >
          <Typography variant={"h5"} sx={{ mb: 2.875 }}>
            聊天室
          </Typography>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: 600,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: theme.palette.grey[500],
              mb: 1.75,
            }}
          >
            <Box>
              <Accordion
                elevation={0}
                sx={{
                  ":last-of-type": {
                    backgroundColor: theme.palette.grey[100],
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
              >
                <Typography variant={"subtitle1"}>重點聊天室訊息</Typography>
              </Accordion>
            </Box>
            <Box
              flexGrow={1}
              sx={{ minHeight: 243, overflowY: "scroll", px: 3, py: 2 }}
            ></Box>
          </Paper>
          <Box
            border={"solid 2px"}
            borderRadius={1}
            display={"flex"}
            flex={1}
            sx={{
              backgroundColor: theme.palette.common.white,
              borderColor: "rgba(0, 0, 0, 0.23)",
              mr: 1.5,
              mb: 3,
              "&:hover, &:focus-within": {
                borderColor: "rgba(0, 0, 0, 0.87)",
              },
            }}
          >
            <InputBase
              sx={{ flex: 1 }}
              autoComplete={"off"}
              placeholder="請輸入訊息"
              {...registerChat("chatText")}
            />
          </Box>
          <Button type={"submit"} variant={"contained"} sx={{ mb: 2 }}>
            送出
          </Button>
        </Grid>
        <Dialog
          open={Boolean(新值班資料)}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={() => 設置新值班資料(null)}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ my: 2, mx: 3 }}
          >
            <Typography variant={"h6"}>編輯資訊</Typography>
            <IconButton onClick={() => 設置新值班資料(null)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <Box display={"flex"} justifyContent={"center"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                sx={{ width: 550, maxWidth: "100%" }}
              >
                <Box>
                  <Typography fontSize={16} lineHeight={1.125} sx={{ mb: 2 }}>
                    標題
                  </Typography>
                  <OutlinedInput sx={{ mb: 2 }} value={"XX的直播"} />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography fontSize={16} lineHeight={1.125} sx={{ mb: 2 }}>
                    類別
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="選擇類別"
                    value={cate}
                    onChange={catehandleChange}
                  >
                    <MenuItem value={1}>遊戲</MenuItem>
                    <MenuItem value={2}>美食</MenuItem>
                    <MenuItem value={3}>喜劇</MenuItem>
                    <MenuItem value={4}>娛樂</MenuItem>
                    <MenuItem value={5}>音樂</MenuItem>
                  </Select>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography fontSize={16} lineHeight={1.125} sx={{ mb: 2 }}>
                    隱私設定
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="隱私權"
                    value={cate}
                    onChange={catehandleChange}
                  >
                    <MenuItem value={1}>公開</MenuItem>
                    <MenuItem value={2}>不公開</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
}
