import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { dateFormatLocal } from "../../utils/date";
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    CircularProgress,
    FormControl,
    OutlinedInput,
    Paper,
    Switch,
    Radio,
    Typography,
    useTheme,
    autocompleteClasses,
  } from "@mui/material";
import { width } from "@mui/system";
import { isTemplateMiddle } from "typescript";

export default function UserProfile() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [使用者選單, 設置使用者選單] = React.useState<"個人檔案" | "直播">("個人檔案");
  const navigate = useNavigate();
  const theme = useTheme();
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
      setEmail(data.email);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/");
    fetchUserName();
  }, [user, loading]);

  const [checked, setChecked] = React.useState({
    connect: true,
    PG: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  const [selectedValue, setSelectedValue] = React.useState('a');

  const radiohandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  return (
    <>
    <Grid  display={"flex"}>
        <Grid  
          item
          xs={12} sm={6} lg={3}
          sx={{ 
          p: 2.625,
          borderRight:2,
          flexDirection: "column",
          width: "20%",
          minHeight: "100%",
          maxWidth: "100%",
          alignItems: "center",
        }}>
            <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
            >  
              <Typography>
                <Typography variant={"h5"}  component={"span"}>
                  歡迎回來!&nbsp;&nbsp;
                </Typography>
                {name}
              </Typography>
          </Box>
          <Grid item sx={{
                width:"100%",
                my:2,
              }}>
            <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
              sx={{
                py:1,
              }}
            >  
              <Typography>
               播放清單
              </Typography>
            </Box>
            <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
              sx={{
                py:1,
              }}
            >  
              <Typography>
               數據分析
              </Typography>
            </Box>
            <Box
                width={"100%"} 
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  py:1,
                }}
              >  
                <Typography>
                  留言
                </Typography>
            </Box>
            <Box
                width={"100%"} 
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  py:1,
                }}
              >  
                <Typography>
                  字幕
                </Typography>
            </Box>
          </Grid>
          <Box
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}
            >  
              <Typography>
                使用者設定
              </Typography>
          </Box>
          <Box 
              width={"100%"} 
              display={"flex"}
              justifyContent={"center"}>
              <Button
                  variant={"text"}
                  sx={{
                  py: 1.375,
                  px: 2,
                  }}
                  onClick={logout}
                  >
                  <Typography fontSize={16} lineHeight={1.125}>
                      登出
                  </Typography>
              </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={8} sx={{ p: 5.25,width: "80%"}}>
          <Typography variant={"h4"} align={"left"} sx={{ flexGrow: 1 }}>
            使用者設定
          </Typography>
          <Grid>
          <Grid container sx={{ my: 6.625 }} spacing={1.5}>
            <Grid item xs={6}>
              <Button
                variant={使用者選單 === "個人檔案" ? "contained" : "outlined"}
                disableRipple={使用者選單 === "個人檔案"}
                fullWidth
                onClick={() => 設置使用者選單("個人檔案")}
              >
                個人檔案
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={使用者選單 === "直播" ? "contained" : "outlined"}
                disableRipple={使用者選單 === "直播"}
                fullWidth
                onClick={() => 設置使用者選單("直播")}
              >
                直播設定
              </Button>
            </Grid>
        </Grid>
        {使用者選單 === "個人檔案" ? (
            <>
                <Typography fontSize={14} align={"left"} sx={{ flexGrow: 1 }}>
                    變更帳號的識別詳細資訊
                </Typography>
                <Paper
                    sx={{
                    maxWidth: "100%",
                    my: 1.5,
                    px: 6.875,
                    py: 3.5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    }}
                >
                    <Box 
                        width={"100%"} 
                        display={"flex"}
                        alignItems={"center"}
                        paddingBottom={"10px"}
                    >
                        <Typography fontSize={14} lineHeight={1.143}  sx={{ pb: 0.5,width:"10%"}}>
                            使用者名稱:&nbsp;&nbsp;
                        </Typography>
                        <OutlinedInput
                        value={email}
                        sx={{ pb: 0.5,width:"90%"}}
                        disabled
                        />
                    </Box>
                    <Box 
                        width={"100%"} 
                        display={"flex"}
                        alignItems={"center"}
                        paddingBottom={"10px"}
                    >
                        <Typography fontSize={14} lineHeight={1.143} sx={{ pb: 0.5,width:"10%"}}>
                            顯示名稱:&nbsp;&nbsp;
                        </Typography>
                        <OutlinedInput
                            value={name}
                            sx={{ pb: 0.5,width:"90%"}}
                        />
                    </Box>
                    <Box 
                        width={"100%"} 
                        display={"flex"}
                        alignItems={"center"}
                        paddingBottom={"10px"}
                    >
                        <Typography fontSize={14} lineHeight={1.143} sx={{ pb: 0.5,width:"10%"}}>
                            自我介紹:&nbsp;&nbsp;
                        </Typography>
                        <OutlinedInput
                            sx={{ pb: 0.5,width:"90%"}}
                        />
                    </Box>
                    <Button
                            variant={"text"}
                            text-align="right"
                            sx={{
                            py: 1.375,
                            px: 2,
                            border: "1px solid #000",
                            }}
                            >
                          <Typography fontSize={16} lineHeight={1.125}>
                            儲存變更
                          </Typography>
                    </Button>
                </Paper>
            </>
            ) : (
                <>
                <Typography fontSize={11} align={"left"} sx={{ flexGrow: 1 }}>
                  串流金鑰與喜好設定
                </Typography>
                <Paper
                  sx={{
                    maxWidth: "100%",
                    my: 1.5,
                    px: 6.875,
                    py: 3.5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                    <Box 
                        width={"100%"} 
                        display={"flex"}
                        alignItems={"center"}
                        paddingBottom={"10px"}
                    >
                        <Typography fontSize={14} lineHeight={1.143}  sx={{ pb: 0.5,width:"10%"}}>
                          主要實況串流金鑰:&nbsp;&nbsp;
                        </Typography>
                        <OutlinedInput
                            sx={{ pb: 0.5,width:"90%"}}
                            value={"live_85838221_JFdO1GMWzaV6sq7nvzBxUlzIZW6q0E"}
                            readOnly={true}
                            type="password"
                        />
                    </Box>
                    <Box 
                        width={"100%"} 
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                        paddingBottom={"10px"}
                    >
                        <Button
                            variant={"text"}
                            sx={{
                            py: 1.375,
                            px: 2,
                            mr:1,
                            border: "1px solid #000",
                            }}
                            >
                          <Typography fontSize={16} lineHeight={1.125}>
                            複製
                          </Typography>
                        </Button>
                        <Button
                            variant={"text"}
                            sx={{
                            py: 1.375,
                            px: 2,
                            ml: 1,
                            border: "1px solid #000",
                            }}
                            >
                          <Typography fontSize={16} lineHeight={1.125}>
                            重設
                          </Typography>
                        </Button>
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      width={"100%"} 
                    >
                      <Typography fontSize={16} lineHeight={1.125} sx={{ pb: 0.5,width:"10%"}}>
                        斷線保護:
                      </Typography>
                      <Box>
                        <Switch
                          checked={checked.connect}
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                          name="connect"
                        />
                        <Typography fontSize={10} lineHeight={1.125} sx={{ py: 1.5}}>
                           在您的實況中斷時啟用備份以改善您實況台的觀賞體驗。
                           如果您的主要實況失效，將在您偵錯並重新連線時，向觀眾顯示暫時圖片 90 秒。
                           *請注意：這項設定需要具備主流使用的編碼器才能正常運作。
                        </Typography>
                      </Box>   
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      width={"100%"} 
                    >
                      <Typography fontSize={16} lineHeight={1.125} sx={{ pb: 0.5,width:"10%"}}>
                        輔導級內容:
                      </Typography>
                      <Box>
                        <Switch
                          checked={checked.PG}
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                          name="PG"
                        />
                        <Typography fontSize={10} lineHeight={1.125} sx={{ py: 1.5}}>
                          如果您的實況包含不適合年輕觀眾收看的內容，請啟用此設定。
                          請勿實況任何色情、裸露、威脅或極端暴力的內容，
                          否則您的帳號將會立即遭到撤銷且無法回復。實況前請確認您的內容符合《服務條款》的規定。
                        </Typography>
                      </Box>   
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      width={"100%"} 
                    >
                      <Typography fontSize={16} lineHeight={1.125} sx={{ pb: 0.5,width:"10%"}}>
                        延遲模式:
                      </Typography>
                      <Box>
                        <Box display={"flex"}>
                          <Radio
                            checked={selectedValue === 'a'}
                            onChange={radiohandleChange}
                            value="a"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                          />
                          <Typography fontSize={10} lineHeight={1.125} sx={{ py: 1.5}}>
                            低延遲：與觀眾近乎零時差互動的最佳選擇
                          </Typography>
                        </Box>
                        <Box display={"flex"}>
                          <Radio
                            checked={selectedValue === 'b'}
                            onChange={radiohandleChange}
                            value="b"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'B' }}
                          />
                          <Typography fontSize={10} lineHeight={1.125} sx={{ py: 1.5}}>
                            一般延遲：如果您不需要與觀眾即時互動，請選擇這個設定。
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </>
            )
        }
          </Grid>
       
        </Grid>
    </Grid>
    
    </>
    
  );
}
