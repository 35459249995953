import { initializeApp } from "firebase/app";
import {GoogleAuthProvider,getAuth,signInWithPopup,signInWithEmailAndPassword,createUserWithEmailAndPassword,sendPasswordResetEmail,signOut,} from "firebase/auth";
import{getFirestore,query,getDocs,collection,where,addDoc,} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAQR_8gg7GvWbP1TsjZO79mFkCe4PUISW4",
  authDomain: "streamingservice-c2848.firebaseapp.com",
  projectId: "streamingservice-c2848",
  storageBucket: "streamingservice-c2848.appspot.com",
  messagingSenderId: "937515176352",
  appId: "1:937515176352:web:e48a9651ecadb3da6154c5",
  measurementId: "G-FVT9CZXYQE"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      console.log("Google-res:",res);
      const token = res.token;
      console.log("Google-res:",res.user.accessToken);
      if (docs.docs.length === 0) {
        await addDoc(collection(db, "users"), {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
        });
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const logInWithEmailAndPassword = async (email, password) => {
    try {
     const res =  await signInWithEmailAndPassword(auth, email, password);
     const token = res.token;
     console.log("token:",token);
     console.log("res:",res.accessToken);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const registerWithEmailAndPassword = async (name, email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      console.log("123");
      console.log("res:",res);
      console.log("user:",user);
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name,
        authProvider: "local",
        email,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const logout = () => {
    signOut(auth);
  };
  export {
    auth,
    db,
    registerWithEmailAndPassword,
    signInWithGoogle,
    logInWithEmailAndPassword,
    sendPasswordReset,
    logout,
  };