import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
// import "./Login.css";
export default function Login() {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user){ 
      alert("成功");
      navigate("/user");
    };
  }, [user, loading]); 
  return (
    <>
    <Box
      display={"flex"}
      justifyContent={"center"}
      sx={{
        py: 5.25,
        px: 8.875,
        [theme.breakpoints.down("lg")]: {
          flexDirection: "column",
          alignItems: "center",
          px: 0,
          py: 1,
        },
      }}
    >
      <Paper
        sx={{
          width: 477,
          minHeight: 564,
          maxWidth: "100%",
          mx: 12.5,
          px: 6.875,
          py: 1.5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
              variant={"h4"}
              fontWeight={"bold"}
              textAlign={"center"}
              sx={{ my: 7.125 }}
            >
              會員登入
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
                <Typography fontSize={14} lineHeight={1.143} sx={{ mb: 0.5 }}>
                  帳號
                </Typography>
                <OutlinedInput
                  placeholder={"請輸入帳號"}
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
                <Typography fontSize={14} lineHeight={1.143} sx={{ mb: 0.5 }}>
                  密碼
                </Typography>
                <OutlinedInput
                  type={"password"}
                  placeholder={"請輸入密碼"}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                />
        </FormControl>
        <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
              <Button
                variant={"text"}
                sx={{
                  py: 1.375,
                  px: 2,
                }}
                onClick={() => navigate("/lawyer/forget")}
              >
                <Typography fontSize={16} lineHeight={1.125}>
                  忘記密碼?
                </Typography>
              </Button>
        </Box>
        <Box width={"100%"}>
            <Button
              type={"submit"}
              variant={"contained"}
              fullWidth
              sx={{
                mb: 3.75,
              }}
              onClick={() => logInWithEmailAndPassword(email, password)}
            >
              登入
            </Button>
        </Box>

        <Button 
              variant={"text"} 
              sx={{
                mb: 3.25,
              }}
              className="login__btn login__google" 
              onClick={signInWithGoogle}
              >
          <Typography fontSize={16} lineHeight={1.125}>
            Login with Google
          </Typography>      
        </Button>
        <Button
              variant={"text"}
              sx={{
                mb: 3.25,
              }}
              onClick={() => navigate("/register")}
              fullWidth
            >
              <Typography fontSize={16} lineHeight={1.125}>
                還沒有帳號嗎?
                <Typography
                  component={"span"}
                  fontSize={"inherit"}
                  lineHeight={"inherit"}
                  fontWeight={"bold"}
                  sx={{
                    textDecoration: "underline",
                  }}
                >
                  立即註冊
                </Typography>
              </Typography>
        </Button>
      </Paper>  
    </Box>
    </>
  );
}
