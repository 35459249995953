import { LocalizationProvider } from "@mui/lab";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import Router from "./Router";
import { AlertProvider } from "./components/Alert";

export default function App() {
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: "16px",
            input: {
              height: 18,
              lineHeight: "18px",
              padding: "15px 16px",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            backgroundColor: "white",
            height: 18,
            minHeight: 18,
            lineHeight: "18px",
            padding: "15px 16px",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "input:not([disabled])": {
              backgroundColor: "white",
            },
            fieldset: {
              borderWidth: 2,
            },
          },
          multiline: {
            backgroundColor: "white",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontSize: "16px",
            lineHeight: "18px",
            padding: "15px 16px",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "16px",
            lineHeight: "18px",
            padding: "15px 16px",
          },
          outlined: {
            borderWidth: 2,
            padding: "13px 16px",
            ":hover": { borderWidth: 2 },
          },
          text: {
            padding: "6px 8px",
          },
        },
      },
    },
  });
  theme.palette.primary.main =
    theme.palette.primary.dark =
    theme.palette.primary.light =
      "#000";
  theme.palette.error.main =
    theme.palette.error.dark =
    theme.palette.error.light =
      "#F44336";
  /**
   * @ANCHOR Font size / Line height
   * Input 16px / 18px
   * Button 16px / 18px
   *
   * H1 96px / 113px
   * H2 60px / 70px
   * H3 48px / 56px
   * H4 36px / 40px
   * H5 24px / 28px
   * H6 20px / 23px
   * subtitle1 16px / 28px
   * subtitle2 14px / 24.5px
   * body1 16px / 24px
   * body2 14px / 20px
   * caption 12px / 14px
   * overline 12px / 14px
   */
  theme.typography.h1.lineHeight = 1.17708333333;
  theme.typography.h2.lineHeight = 1.16666666667;
  theme.typography.h3.lineHeight = 1.16666666667;
  theme.typography.h4.lineHeight = 1.11111111111;
  theme.typography.h5.lineHeight = 1.16666666667;
  theme.typography.h6.lineHeight = 1.15;
  theme.typography.subtitle2.lineHeight = 1.75;
  theme.typography.body2.lineHeight = 1.42857142857;
  theme.typography.caption.lineHeight = 1.16666666667;
  theme.typography.overline.lineHeight = 1.16666666667;
  /**
   * @ANCHOR Font weight
   * H1 ~ H6 700(Bold)
   * other 400(Regular)
   */
  theme.typography.h1.fontWeight =
    theme.typography.h2.fontWeight =
    theme.typography.h3.fontWeight =
    theme.typography.h4.fontWeight =
    theme.typography.h5.fontWeight =
    theme.typography.h6.fontWeight =
      700;
  theme.typography.subtitle2.fontWeight = 400;
  theme.typography.button.fontWeight = 400;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <Box sx={{ backgroundColor: theme.palette.grey[100] }}>
            <Router />
          </Box>
        </AlertProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
