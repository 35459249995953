import { Box, Link, Typography, useTheme } from "@mui/material";
import { useNavigate, useOutlet } from "react-router-dom";

import androidDownloadImage from "../../images/Google Play.svg";
import iosDownloadImage from "../../images/App Store.svg";
import NavBar, { NavBarProps } from "../NavBar";

export type BaseLayoutProps = Omit<NavBarProps, "height"> & {
  navbar?: JSX.Element;
  footer?: { text: string; url: string } | false;
};

export default function BaseLayout({
  navbar,
  footer,
  ...props
}: BaseLayoutProps) {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const theme = useTheme();
  const navbarHeight = 72;
  const footerImgSize = 52;
  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ minHeight: "100vh" }}>
      <NavBar {...props} height={navbarHeight}>
        <Box
          display={"flex"}
          sx={{
            padding: "17px 0px 20px",
            cursor: "pointer",
            mr: 3,
          }}
          onClick={() => navigate("/")}
        >
        </Box>
        <Box display={"flex"} sx={{ flexGrow: 1 }}>
          {navbar}
        </Box>
      </NavBar>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {outlet || props.children}
      </Box>
      {footer === false ? null : (
        <Box sx={{ py: 2.25, backgroundColor: theme.palette.primary.main }}>
          <Box
            display={"flex"}
            sx={{
              justifyContent: "center",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
          </Box>
          <Box
            display={"flex"}
            height={32}
            sx={{
              my: 0.625,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {footer ? (
              <Link href={footer.url}>
                <Typography
                  variant={"caption"}
                  align={"center"}
                  color={theme.palette.grey[500]}
                >
                  {footer.text}
                </Typography>
              </Link>
            ) : null}
          </Box>
          <Box textAlign={"center"}>
            <Typography variant={"caption"} color={theme.palette.common.white}>
              Copyright 2018-2021 版權所有  葵曜系統有限公司
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
