import { Box, Typography } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

import BaseLayout from "./components/layout/BaseLayout";


import Login from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import RegisterPage from "./pages/Register";
import CreateLiveStream from "./pages/CreateLiveStreamPage";
import Dashboard from "./pages/user/Dashboard";
import LiveComment from "./pages/user/LiveComment";
import UserProfile from "./pages/user/UserProfile";

export default function Router() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path={"/"} element={<BaseLayout />}>
          <Route index element={<Login />} />
        </Route>
        <Route path={"register"} element={<RegisterPage />} />
        <Route path={"user"} element={<BaseLayout />}>
          <Route index element={<Dashboard />} />
        </Route>
        <Route path={"CreateLiveStream"} element={<BaseLayout />}>
          <Route index element={<CreateLiveStream />} />
        </Route>
        <Route path={"user/comment"} element={<BaseLayout />}>
          <Route index element={<LiveComment />} />
        </Route>
        <Route path={"register"} element={<BaseLayout />}>
          <Route index element={<RegisterPage />} />
        </Route>
        <Route path={"/user/UserProfile"} element={<BaseLayout />}>
          <Route index element={<UserProfile />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
